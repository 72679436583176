import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'

class ThanksPage extends React.Component {

  render() {

    return (
      <Layout>
        <div>
        <Helmet title={`Contact`} />
        <div className="content">
          <div className="content__inner">
            <div className="page">
              <h1 className="page__title">Thank you !</h1>
              <div className="page__body">
                <div>
                  <p>お問い合わせいただきありがとうございます!</p>
                  <p>数日中に返信いたします。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidebar {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default ThanksPage

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          github
        }
      }
    }
  }
`